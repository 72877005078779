
:root {
  /* TODO: move into .m-input selector */
  --input-width: 100%;
  --input-height: 32px;
  --input-padding: 8px;
  --input-margin: 8px;
  --input-icon-offset: 8px;
  --input-transition: 250ms ease-in-out;
  --input-message-spacing: 6px;
  --input-label-spacing: 8px;
  --input-small-height: 28px;
  --input-large-height: 40px;
}
.m-input {
  font-weight: var(--main-font-weight);

  display: flex;

  flex-direction: column;
}
.m-input_disabled {
  cursor: not-allowed;
}
.m-input__label {
  font-family: var(--main-font-family);
  font-size: var(--main-font-size);
  font-weight: var(--medium-font-weight);

  /* overriding application.css */
  color: var(--text-main-color);
  line-height: var(--main-line-height);

  margin-bottom: 8px;

  margin-bottom: var(--input-label-spacing);
}
.m-input__field-wrapper {
  position: relative;
  display: flex;

  justify-content: space-between;
  align-items: center;
}
.m-input .m-input__field {
  font-family: var(--main-font-family);

  color: var(--secondary-text-color);

  box-sizing: border-box;
  width: 100%;
  width: var(--input-width);
  height: 32px;
  height: var(--input-height);

  padding-left: 8px;

  padding-left: var(--input-padding);
  padding-right: 8px;
  padding-right: var(--input-padding);

  /* deal with application.css */
  background: var(--background-color);

  border: solid 1px var(--border-color);

  border-radius: var(--border-radius);

  outline: none;

  transition: 250ms ease-in-out;

  transition: var(--input-transition);
}
.m-input__icon {
  flex-shrink: 0;

  width: var(--main-icon-size);
  height: var(--main-icon-size);
}
.m-input__message {
  margin-top: 6px;
  margin-top: var(--input-message-spacing);
}
.m-input input {
  font-size: 14px;

  /* fix bootstrap styles */
  margin: 0;
}

/* stylelint-disable-next-line selector-no-qualifying-type */
.m-input input[type='password'] {
  /* fix bootstrap styles */
  margin-bottom: 0;
}
.m-input_small input {
  font-size: 12px;
}
.m-input_small .m-input__field {
  height: 28px;
  height: var(--input-small-height);
}
.m-input_large .m-input__label,
.m-input_large input {
  font-size: var(--subtitle-font-size);
  line-height: var(--subtitle-line-height);
}
.m-input_large .m-input__field {
  height: 40px;
  height: var(--input-large-height);
}
.m-input_disabled .m-input__field {
  color: var(--gray-text-color);

  cursor: not-allowed;

  background-color: var(--background-gray-color);

  border-color: var(--background-gray-color);
}
.m-input_inset-icon .m-input__field {
  padding-right: calc(var(--main-icon-size) + 2 * 8px);
  padding-right: calc(var(--main-icon-size) + 2 * var(--input-padding));
}
.m-input_inset-icon .m-input__icon {
  position: absolute;
  right: 8px;
  right: var(--input-padding);
}
.m-input_outset-icon .m-input__field {
  margin-right: 8px;
  margin-right: var(--input-icon-offset);
}
.m-input_success .m-input__field {
  border-color: var(--success-color);
}
.m-input_outset-icon .m-input_success .m-input__icon,
.m-input_success .m-input__message {
  color: var(--success-color);
}
.m-error-wrapper__active .m-input__field,
.m-input_error .m-input__field {
  border-color: var(--error-color);
}
.m-input_outset-icon .m-input_error .m-input__icon,
.m-input_error .m-input__message {
  color: var(--error-color);
}

/* it's just a hot fix for placeholder */
.m-input .m-input__field::-webkit-input-placeholder {  /* stylelint-disable-line selector-no-vendor-prefix */
  position: relative;
  top: 1px;
}
.m-input:not(.m-input_success):not(.m-input_error) .m-input__field:not(:disabled):focus,
.m-input:not(.m-input_success):not(.m-input_error) .m-input__field:not(:disabled):hover {
  border-color: var(--action-color);
}
